<script>
import TablePage from '@/found/components/table_page';

export default {
  extends: TablePage,
  name: 'grading_factors',
  components: {},
  data() {
    return {};
  },
  created() {
    this.getConfigList('grading_factors');
  },
  methods: {},
};
</script>
